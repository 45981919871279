import request from '@/utils/request';

/**
 * 保存白名单用户
 * @param {Object} data
 */
export function saveWhiteUser(data) {
  return request({
    url: '/user/whiteList/saveWhiteUser',
    method: 'post',
    data
  });
}

/**
 * 导入白名单用户
 */
export function uploadUser(data) {
  return request({
    url: '/user/whiteList/uploadUser',
    method: 'post',
    data
  });
}

/**
 * 下载模板
 * @param {Object} _filename
 */
export function downloadModel(_filename) {
  return request({
    url: '/user/whiteList/downloadModel',
    method: 'get',
    params: {
      fileName: _filename
    }
  });
}

/** 查询白名单用户
 * @param {Object} data
 */
export function getWhiteUserList(data) {
  return request({
    url: '/user/whiteList/getWhiteList',
    method: 'post',
    data
  });
}

/**
 * 删除白名单用户
 * @param {Object}
 */
export function delWhiteUser(data) {
  return request({
    url: '/user/whiteList/delWhite',
    method: 'post',
    data
  });
}

/**
 * 根据白名单批量审核用户
 * @param {Object} data
 */
export function batchAuditUser(data) {
  return request({
    url: '/user/whiteList/batchAuditUser',
    method: 'post',
    data
  });
}
