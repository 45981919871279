<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-button
          type="primary"
          icon="el-icon-download"
          class="mr10"
          @click="downloadModel"
        >
          下载模板
        </el-button>
        <el-upload
          class="upload-file"
          :before-upload="beforeUpload"
          :show-file-list="false"
          action=""
          list-type="file"
          :http-request="uploadUser"
        >
          <el-button
            slot="trigger"
            v-loading="dlgLoading"
            icon="el-icon-upload2"
            type="primary"
            element-loading-text="正在导入..."
          >
            导入
          </el-button>
        </el-upload>
        <el-button
          type="success"
          icon="el-icon-plus"
          class="mr10"
          @click="onAdd"
        >
          添加
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          class="mr10"
          @click="delAll"
        >
          批量删除
        </el-button>
        <el-tooltip
          class="item"
          effect="dark"
          content="将根据导入的用户姓名/身份证号码自动审核微信小程序用户"
          placement="top"
        >
          <el-button
            v-loading="isBatchLoading"
            type="success"
            icon="el-icon-edit"
            class="mr10"
            @click="auditAll"
          >
            批量审核
          </el-button>
        </el-tooltip>
        <el-button icon="el-icon-refresh" class="mr10" @click="onRefresh">
          刷新
        </el-button>
      </div>
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="name">
          <el-input
            v-model="query.name"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="telephone">
          <el-input
            v-model="query.telephone"
            placeholder="请输入联系方式"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="cardno">
          <el-input
            v-model="query.cardno"
            placeholder="请输入身份证号、警号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="listLoading"
        :data="list"
        element-loading-text="正在加载 ..."
        border
        fit
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="姓名" prop="name" />
        <el-table-column label="联系方式" prop="telephone" />
        <el-table-column label="警号" prop="policeCard" />
        <el-table-column label="身份证号码" prop="idCard" width="200" />
        <el-table-column label="导入时间" prop="importTime" />
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="onEdit(scope.row)">
              编辑
            </el-button>
            <el-button type="danger" size="small" @click="onDel(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageInfo.startPage"
          :page-sizes="[10, 20, 30, 50, 100, 200, 500]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
          @size-change="onSizeChange"
          @current-change="onPageChange"
        />
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑信息"
      width="800px"
      :visible.sync="selectDlgShow"
      :close-on-click-modal="false"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form
          ref="ruleForm"
          :rules="rules"
          :model="form"
          label-width="120px"
        >
          <el-form-item label="真实姓名" prop="name">
            <el-input
              v-model="form.name"
              type="text"
              placeholder="请输入真实姓名"
              clearable
            />
          </el-form-item>
          <el-form-item label="手机号码" prop="telephone">
            <el-input
              v-model="form.telephone"
              type="text"
              placeholder="请输入手机号码"
              clearable
            />
          </el-form-item>
          <el-form-item label="身份证号码" prop="idCard">
            <el-input
              v-model="form.idCard"
              type="text"
              placeholder="请输入身份证"
              clearable
            />
          </el-form-item>
          <el-form-item label="警号" prop="policeCard">
            <el-input
              v-model="form.policeCard"
              type="text"
              placeholder="请输入警员编号"
              clearable
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" plain @click="selectDlgShow = false">
          关 闭
        </el-button>
        <el-button size="small" @click="resetForm('ruleForm')">重 置</el-button>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">
          保 存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  uploadUser,
  getWhiteUserList,
  delWhiteUser,
  saveWhiteUser,
  downloadModel,
  batchAuditUser
} from '@/api/app/whiteUser';
import { Message, MessageBox } from 'element-ui';
import { constants } from 'fs';
import Validators from '@/components/common/validata.js';

export default {
  data() {
    return {
      query: {
        name: '',
        telephone: '',
        cardno: ''
      },
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        name: '',
        telephone: '',
        cardno: ''
      },
      list: [],
      form: {
        id: '',
        name: '',
        telephone: '',
        idCard: '',
        policeCard: ''
      },
      rules: {
        telephone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: Validators.checkPhone, trigger: 'blur' }
        ],
        idCard: [
          { required: false, message: '请输入身份证号码', trigger: 'blur' },
          { validator: Validators.checkIdCard, trigger: 'blur' }
        ]
      },
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      isBatchLoading: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.pageInfo.name = this.query.name;
      this.pageInfo.telephone = this.query.telephone;
      this.pageInfo.cardno = this.query.cardno;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    onAdd() {
      this.form = {
        id: '',
        name: '',
        telephone: '',
        idCard: '',
        policeCard: ''
      };
      this.selectDlgShow = true;
    },
    onEdit(row) {
      this.form = {
        id: row.id,
        name: row.name,
        telephone: row.telephone,
        idCard: row.idCard,
        policeCard: row.policeCard
      };
      this.selectDlgShow = true;
    },
    delAll() {
      var ids = [];
      this.multipleSelection.forEach(item => {
        ids.push(item.id);
      });
      if (ids.length == 0 || ids == null) {
        this.$message.warning('请至少选择一项');
        return;
      }

      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delWhiteUser({ ids: ids }).then(resp => {
          this.$message({
            type: 'success',
            message: resp.msg
          });
          this.fetchData();
        });
      });
    },
    onDel(row) {
      this.$confirm('您确定要删除此用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delWhiteUser({ id: row.id }).then(resp => {
          this.$message({
            type: 'success',
            message: resp.msg
          });
          this.fetchData();
        });
      });
    },
    //批量审核
    auditAll() {
      var ids = [];
      this.multipleSelection.forEach(item => {
        ids.push(item.id);
      });

      let msg = '';
      if (ids.length === 0) {
        msg =
          '您确定要审核所有的用户吗？用户过多时审核时间可能比较长，建议您先去休息一会!';
      } else {
        msg = '您确定要审核所选择的' + ids.length + '个用户吗？';
      }

      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.isBatchLoading = true;
        batchAuditUser({
          ids: ids
        }).then(resp => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '审核成功'
            });
          } else {
            this.$message.error('审核失败');
          }
          this.isBatchLoading = false;
        });
      });
    },
    fetchData() {
      this.listLoading = true;
      getWhiteUserList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    save() {
      this.dlgLoading = true;
      saveWhiteUser(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else if (resp.code == 300) {
            this.$message({
              message: resp.msg,
              type: 'warning'
            });
          } else {
            this.$message.error(resp.msg);
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    beforeUpload(file) {
      const isText = file.type === 'application/vnd.ms-excel';
      if (!isText) {
        this.$message.error('只能上传Excel文件格式');
        return false;
      }
    },
    uploadUser(item) {
      this.dlgLoading = true;
      const fileObj = item.file;
      const form = new FormData();
      form.append('file', fileObj);
      uploadUser(form)
        .then(response => {
          if (response.code == 200) {
            this.$message({
              message: '导入成功',
              type: 'success'
            });
            this.dlgLoading = false;
            this.onRefresh();
          } else {
            this.dlgLoading = false;
            this.$message.error('导入失败');
          }
        })
        .catch(error => {
          this.dlgLoading = false;
          this.$message.error('系统错误');
        });
    },
    //下载模板
    downloadModel() {
      const fileName = 'user_model.xls';
      downloadModel(fileName).then(resp => {
        let a = document.createElement('a');
        a.href = resp.msg;
        a.click();
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.upload-file {
  display: inline-block;
  margin-right: 10px;
}
</style>
